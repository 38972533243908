import { Component, inject, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthorisationService } from '@services/authorisation.service'
import { ProficloudService } from '@services/proficloud.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  private auth = inject(AuthorisationService)

  private proficloud = inject(ProficloudService)

  private router = inject(Router)

  organisationLoadedSubscription: Subscription

  ngOnInit(): void {
    if (this.proficloud.currentOrganisation) {
      this.redirectNonAdmins()
    } else {
      this.organisationLoadedSubscription = this.proficloud.organisationsListed$.subscribe({
        next: () => {
          this.redirectNonAdmins()
        },
      })
    }
  }

  redirectNonAdmins() {
    if (!this.auth.isAdmin(this.proficloud.currentOrganisation)) {
      this.router.navigate(['/services/device-management/list'])
    }
  }

  ngOnDestroy(): void {
    this.organisationLoadedSubscription?.unsubscribe()
  }
}
